import React, { useCallback, useRef, useState } from 'react';
import { FiMail, FiUser, FiSend } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, Background, LanguageBox } from './styles';

interface Request {
  name: string;
  email: string;
  currency: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [currency, setCurrency] = useState('USD');
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data: Request) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(t('Nome obrigatório')),
          email: Yup.string()
            .required(t('E-mail obrigatório'))
            .email(t('Digite um e-mail válido.')),
          currency: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        localStorage.setItem('@Nominal:currency', currency);
        localStorage.setItem('@Nominal:user', JSON.stringify(data));
        history.push('/choose-service');
      } catch (err) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    },
    [history, currency, t],
  );

  const handleChangeCurrency = useCallback(e => {
    setCurrency(e.target.value);
  }, []);

  const handleChangeLanguage = useCallback(e => {
    i18n.changeLanguage(e.target.value);
  }, []);

  return (
    <Container>
      <Content>
        <img src={logoImg} alt="Nominal 3D" width="300" />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <LanguageBox>
            <label htmlFor="en">
              <input
                type="radio"
                name="language"
                id="en"
                value="en"
                defaultChecked
                onChange={handleChangeLanguage}
              />
              {t('Inglês')}
            </label>
            <label htmlFor="ptbr">
              <input
                type="radio"
                name="language"
                id="ptbr"
                value="ptbr"
                onChange={handleChangeLanguage}
              />
              {t('Português')}
            </label>
          </LanguageBox>
          <h1>{t('Faça seu orçamento')}</h1>

          <Input
            name="name"
            icon={FiUser}
            placeholder={t('Seu nome')}
            autoFocus
          />
          <Input name="email" icon={FiMail} placeholder={t('Seu e-mail')} />
          <div>
            <label htmlFor="currencyUSD">
              <input
                type="radio"
                name="currency"
                id="currencyUSD"
                value="USD"
                defaultChecked
                onChange={handleChangeCurrency}
              />
              {t('Dolár')} ($)
            </label>
            <label htmlFor="currencyBRL">
              <input
                type="radio"
                name="currency"
                id="currencyBRL"
                value="BRL"
                onChange={handleChangeCurrency}
              />
              {t('Real')} (R$)
            </label>

            <label htmlFor="currencyEUR">
              <input
                type="radio"
                name="currency"
                id="currencyEUR"
                value="EUR"
                onChange={handleChangeCurrency}
              />
              {t('Euro')} (€)
            </label>
          </div>
          <Button type="submit">{t('Começar')}</Button>
        </Form>
        {t('Precisa de suporte 3D para sua campanha publicitária?')}
        <a href="https://api.whatsapp.com/send?l=pt&phone=5514997830667">
          <FiSend />
          {t('Fale conosco')}
        </a>
      </Content>
      <Background />
    </Container>
  );
};

export default Login;
