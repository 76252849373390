import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FiHelpCircle } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import { useTranslation } from 'react-i18next';
import logoImg from '../../assets/logo.png';

import Button from '../../components/Button';
import api from '../../services/api';

import {
  Container,
  Content,
  Subtotal,
  Total,
  // ButtonSuccess,
  ButtonOutline,
  MaxWidth,
} from './styles';
import cielo from '../../services/cielo';

interface Price {
  photos: number;
  video: number;
  total: number;
  days: number;
}

interface User {
  name: string;
  email: string;
}

interface Request {
  rates: {
    [key: string]: string;
  };
}

const Budget: React.FC = () => {
  const [user, setUser] = useState<User>({ name: '', email: '' });

  const [currency] = useState(
    localStorage.getItem('@Nominal:currency') || 'USD',
  );
  const [modelingFootage] = useState(
    Number(localStorage.getItem('@Nominal:modelingFootage')) || 0,
  );

  const [modelingDays] = useState(
    Number(localStorage.getItem('@Nominal:modelingDays')) || 0,
  );

  const [language] = useState(() => {
    switch (currency) {
      case 'BRL':
        return 'pt-BR';
      case 'USD':
        return 'en-US';
      case 'EUR':
        return 'en-US';
      default:
        return '';
    }
  });
  const [rate, setRate] = useState(0);
  const history = useHistory();

  const [priceOuter, setPriceOuter] = useState<Price>({
    photos: 0,
    video: 0,
    total: 0,
    days: 0,
  });

  const [priceInner, setPriceInner] = useState<Price>({
    photos: 0,
    video: 0,
    total: 0,
    days: 0,
  });

  const formatter = useCallback(
    (value: number) => {
      const newValue = value * rate;

      const numberFormat = new Intl.NumberFormat(language, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
      });

      return numberFormat.format(newValue);
    },
    [currency, language, rate],
  );

  const redirectToServiceType = useCallback(() => {
    history.push('service-type');
  }, [history]);

  const countDays = useCallback((days: number[]) => {
    const totalDays = Math.round(days.reduce((total, day) => total + day));
    let deadline = 0;

    if (totalDays > 0 && totalDays <= 5) {
      deadline = totalDays + 2;
    }
    if (totalDays > 5 && totalDays <= 10) {
      deadline = totalDays + 3;
    }
    if (totalDays > 10 && totalDays <= 15) {
      deadline = totalDays + 5;
    }
    if (totalDays > 15) {
      deadline = totalDays + 7;
    }

    return deadline;
  }, []);

  const handleResetBudget = useCallback(() => {
    localStorage.removeItem('@Nominal:priceOuter');
    localStorage.removeItem('@Nominal:priceInner');
    localStorage.removeItem('@Nominal:constructionType');
    localStorage.removeItem('@Nominal:modelingDays');
    localStorage.removeItem('@Nominal:modelingFootage');

    history.push('/modeling');
  }, [history]);

  useEffect(() => {
    if (localStorage.getItem('@Nominal:user')) {
      setUser(JSON.parse(localStorage.getItem('@Nominal:user') || '{}'));
    } else {
      history.push('/');
    }

    if (localStorage.getItem('@Nominal:priceOuter')) {
      setPriceOuter(
        JSON.parse(localStorage.getItem('@Nominal:priceOuter') || '{}'),
      );
    }

    if (localStorage.getItem('@Nominal:priceInner')) {
      setPriceInner(
        JSON.parse(localStorage.getItem('@Nominal:priceInner') || '{}'),
      );
    }

    if (localStorage.getItem('@Nominal:currency')) {
      api.get('&base=BRL').then<Request>(response => {
        setRate(response.data.rates[currency]);
        return response.data;
      });
    }

    // const data = {
    //   type: 'Service',
    //   name: 'Nominal3D',
    //   description: 'Nominal3D Studio de arquitetura 3D',
    //   price: priceInner.total + priceOuter.total + modelingFootage,
    //   maxNumberOfInstallments: '1',
    //   quantity: 1,
    //   Sku: 'Nominal3D',
    //   shipping: {
    //     type: 'WithoutShipping',
    //     name: 'teste',
    //     price: '1000000000',
    //   },
    //   SoftDescriptor: 'Nominal3D',
    // };

    cielo
      .post(
        '/v2/token',
        {},
        {
          headers: {
            Authorization:
              'Basic NzI1ZjU3NzQtMGRiMC00MmEyLTk1ZTYtNzg3ZWRmMjY1ZDI2Om4xeVFRZ2pLem1INEhVRVIxYWVjTGJKdmhJVk93aXY3VDREelZscTNXczA9',
            ContentType: 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(response => {
        console.log(response);
      });
  }, [history, currency, modelingFootage, priceInner.total, priceOuter.total]);

  const { t } = useTranslation();

  return (
    <Container>
      <Content outer={!!priceOuter.total} inner={!!priceInner.total}>
        <img className="logo" src={logoImg} alt="Nominal 3D" width="180" />
        <h1>
          {t('Olá {{username}}, este é o seu investimento:', {
            username: user.name,
          })}
        </h1>

        {modelingFootage > 0 && (
          <Subtotal>
            <h2>{t('Modelagem')}</h2>
            <h3>
              <strong>{t('Subtotal')}</strong> {formatter(modelingFootage)}
            </h3>
            {(priceOuter.days !== 0 || priceInner.days !== 0) && (
              <h4>
                {t('Prazo de')} {Math.round(modelingDays)}{' '}
                {modelingDays === 1 ? t('dia') : t('dias')}
              </h4>
            )}
          </Subtotal>
        )}
        <MaxWidth outer={!!priceOuter.total} inner={!!priceInner.total}>
          {priceOuter.total > 0 && (
            <Subtotal>
              <h2>{t('Arquitetura Externa')}</h2>
              <p>
                <strong>{t('Fotos')}</strong> {formatter(priceOuter.photos)}
              </p>
              <p>
                <strong>{t('Vídeo')}</strong> {formatter(priceOuter.video)}
              </p>
              <h3>
                {t('Subtotal')} {formatter(priceOuter.total)}
              </h3>
              {(modelingDays !== 0 || priceInner.days !== 0) && (
                <h4>
                  {t('Prazo de')} {Math.round(priceOuter.days)}{' '}
                  {priceOuter.days === 1 ? t('dia') : t('dias')}
                </h4>
              )}
            </Subtotal>
          )}

          {priceInner.total > 0 && (
            <Subtotal>
              <h2>{t('Arquitetura Interna')}</h2>
              <p>
                <strong>{t('Fotos')}</strong> {formatter(priceInner.photos)}
              </p>
              <p>
                <strong>{t('Vídeo')}</strong> {formatter(priceInner.video)}
              </p>
              <h3>
                {t('Subtotal')} {formatter(priceInner.total)}
              </h3>
              {(modelingDays !== 0 || priceOuter.days !== 0) && (
                <h4>
                  Prazo de {Math.round(priceInner.days)}{' '}
                  {priceInner.days === 1 ? t('dia') : t('dias')}
                </h4>
              )}
            </Subtotal>
          )}
        </MaxWidth>
        <Total>
          <h3>{t('Valor total de:')}</h3>
          <h1>
            {formatter(priceInner.total + priceOuter.total + modelingFootage)}{' '}
            <span
              data-tip={t(
                'Caro cliente, após concluir a compra de nossos serviços, você poderá realizar uma revisão, com direito a ajustes e adequações no projeto. Caso sejam necessárias mais revisões, será cobrada uma tarifa adicional. Para mais informações entre em contato com nossa equipe. Valor válido apenas no presente momento.',
              )}
            >
              <FiHelpCircle />
              <ReactTooltip place="top" type="dark" effect="float" html />
            </span>
          </h1>
          <h4>
            {t('O prazo para entrega é de')}{' '}
            {countDays([modelingDays, priceInner.days, priceOuter.days])}{' '}
            {t('dias')}
          </h4>
          <h4>
            {t('Precisa do serviço mais rápido?')}{' '}
            <a href="https://api.whatsapp.com/send?l=pt&phone=5514997830667">
              {t('Fale Conosco')}
            </a>
          </h4>
        </Total>
        {priceOuter.total <= 0 && priceInner.total <= 0 && (
          <Button onClick={redirectToServiceType}>
            {t('Quero ver os serviços de renderização também!')}
          </Button>
        )}

        <ButtonOutline
          onClick={() => {
            window.open(
              'https://api.whatsapp.com/send?l=pt&phone=5514997830667',
              '_blank',
            );
          }}
        >
          {t('Faça sua encomenda')}
        </ButtonOutline>
        <form
          method="post"
          action="https://cieloecommerce.cielo.com.br/transactionalvnext/order/buynow"
          target="blank"
        >
          <input
            type="hidden"
            name="id"
            value="2cd9e419-15ee-4c1d-b377-32e3691ddac1"
          />
          {/* <ButtonSuccess type="submit" name="submit">
            {t('Realizar pagamento')}
          </ButtonSuccess> */}
        </form>

        <ButtonOutline onClick={handleResetBudget} disabled>
          {t('Arquivos do projeto')}
        </ButtonOutline>
        <ButtonOutline onClick={handleResetBudget} style={{ marginTop: 30 }}>
          {t('Refazer orçamento')}
        </ButtonOutline>
      </Content>
    </Container>
  );
};

export default Budget;
