import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { FiTrash2, FiHelpCircle } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Container,
  Content,
  InputBox,
  ButtonOutline,
  ButtonLink,
  ModalCustom as Modal,
} from './styles';

interface Price {
  photos: number;
  video: number;
  total: number;
  days: number;
}

interface Photo {
  [key: string]: string | number | undefined;
  name: string;
  quantity: number | undefined;
}

interface Video {
  [key: string]: string | number | undefined;
  description: string;
  seconds: number | undefined;
}

const Inner: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<'furnished' | 'suggested'>(
    'furnished',
  );
  const [constructionType] = useState(
    localStorage.getItem('@Nominal:constructionType') || '',
  );

  const [photos, setPhotos] = useState<Photo[]>([]);
  const [videos, setVideos] = useState<Video[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const priceInner: Price = {
        photos: 0,
        video: 0,
        total: 0,
        days: 0,
      };

      if (photos) {
        photos.forEach((photo): void => {
          if (photo.quantity) {
            if (selectedType === 'suggested') {
              priceInner.photos += photo.quantity * 400 + 400;

              priceInner.days += Number(photo.quantity * 3);
            } else {
              priceInner.photos += photo.quantity * 300 + 300;

              priceInner.days += Number(photo.quantity);
            }
          }
        });

        priceInner.total += priceInner.photos;
      }

      if (videos) {
        videos.forEach((video): void => {
          if (video.seconds) {
            if (constructionType === 'edificio') {
              priceInner.video += video.seconds * 35;

              priceInner.days += Number(video.seconds * 0.25);
            } else {
              priceInner.video += video.seconds * 25;

              priceInner.days += Number(video.seconds * 0.168);
            }
          }
        });

        priceInner.total += priceInner.video;
      }

      localStorage.setItem('@Nominal:priceInner', JSON.stringify(priceInner));

      history.push('/budget');
    } catch (err) {
      const errors = getValidationErrors(err);

      formRef.current?.setErrors(errors);
    }
  }, [history, selectedType, photos, videos, constructionType]);

  const handleInputChangePhotos = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    const name = e.target.getAttribute('inputname');

    const list: Photo[] = [...photos];

    if (name) {
      list[index][name] = value;
    }

    setPhotos(list);
  };

  const handleRemoveClickPhotos = (index: number) => {
    const list = [...photos];
    list.splice(index, 1);
    setPhotos(list);
  };

  const handleAddClickPhotos = () => {
    setPhotos([...photos, { name: '', quantity: undefined }]);
  };

  const handleInputChangeVideos = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    const name = e.target.getAttribute('inputname');

    const list: Video[] = [...videos];

    if (name) {
      list[index][name] = value;
    }

    setVideos(list);
  };

  const handleRemoveClickVideos = (index: number) => {
    const list = [...videos];
    list.splice(index, 1);
    setVideos(list);
  };

  const handleAddClickVideos = () => {
    setVideos([...videos, { description: '', seconds: undefined }]);
  };

  return (
    <Container>
      <Content>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
          integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
          crossOrigin="anonymous"
        />
        <img className="logo" src={logoImg} alt="Nominal 3D" width="180" />

        <Form
          initialData={{
            selectedModeling: true,
            selectedPhotos: true,
            selectedVideo: true,
          }}
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <h1>{t('Arquitetura Interna')}</h1>

          <InputBox>
            <label
              htmlFor="selectedFurnished"
              data-tip={t(
                'Para o interior mobiliado, o cliente precisará enviar o modelo em 3D finalizado.',
              )}
            >
              <input
                type="radio"
                name="selectedType"
                id="selectedFurnished"
                value="furnished"
                defaultChecked
                onChange={() => setSelectedType('furnished')}
              />
              {t('Interior Mobiliado')} <FiHelpCircle />
              <ReactTooltip place="top" type="dark" effect="float" />
            </label>
            <label
              htmlFor="selectedSuggested"
              data-tip={t(
                'Para o interior sugerido, a Nominal 3D fica responsável por desenvolver a decoração e mobília do projeto.',
              )}
            >
              <input
                type="radio"
                name="selectedType"
                id="selectedSuggested"
                value="suggested"
                onChange={() => setSelectedType('suggested')}
              />
              {t('Interior Sugerido')} <FiHelpCircle />
              <ReactTooltip place="top" type="dark" effect="float" />
            </label>
          </InputBox>
          <br />
          <InputBox>
            <p>{t('Fotos')}</p>
            {photos &&
              photos.map((photo, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <section key={`photo_${index}`}>
                  <Input
                    name="name"
                    inputname="name"
                    placeholder={t('Nome do ambiente')}
                    value={photo.name}
                    onChange={event => handleInputChangePhotos(event, index)}
                  />
                  <Input
                    type="number"
                    name="quantity"
                    inputname="quantity"
                    placeholder={t('Quantidade de fotos do ambiente')}
                    value={photo.quantity}
                    onChange={event => handleInputChangePhotos(event, index)}
                  />

                  <Button
                    type="button"
                    onClick={() => handleRemoveClickPhotos(index)}
                  >
                    <FiTrash2 size={20} />
                  </Button>
                </section>
              ))}
            <ButtonOutline onClick={handleAddClickPhotos}>
              {t('Adicionar ambiente')}
            </ButtonOutline>
          </InputBox>
          <InputBox>
            <p>
              {t('Vídeos')}{' '}
              <ButtonLink onClick={handleShow}>
                {t('Como saber o tempo ideal de vídeo para o meu projeto?')}
              </ButtonLink>
            </p>
            {videos &&
              videos.map((video, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <section key={`video_${index}`}>
                  <Input
                    name="description"
                    inputname="description"
                    placeholder={t('Descrição do vídeo')}
                    value={video.description}
                    onChange={event => handleInputChangeVideos(event, index)}
                  />
                  <Input
                    type="number"
                    name="seconds"
                    inputname="seconds"
                    placeholder={t('Qual a duração em segundos?')}
                    value={video.seconds}
                    onChange={event => handleInputChangeVideos(event, index)}
                  />

                  <Button
                    type="button"
                    onClick={() => handleRemoveClickVideos(index)}
                  >
                    <FiTrash2 size={20} />
                  </Button>
                </section>
              ))}
            <ButtonOutline onClick={handleAddClickVideos}>
              {t('Adicionar vídeo')}
            </ButtonOutline>
          </InputBox>

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t('Como saber o tempo ideal de vídeo para o meu projeto?')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t(
                'Separamos alguns modelos com tempo estimado para cada cômodo:',
              )}
              <Table responsive variant="light">
                <tbody>
                  <tr>
                    <td>{t('Fachada de casa')}</td>
                    <td>{t('15 segundos')}</td>
                  </tr>
                  <tr>
                    <td>{t('Fachada de edifício')}</td>
                    <td>{t('20 a 30 segundos')}</td>
                  </tr>
                  <tr>
                    <td>{t('Ambientes internos')}</td>
                    <td>{t('12 segundos cada cômodo')}</td>
                  </tr>
                  <tr>
                    <td>{t('Ambientes pequenos (ex: Banheiro)')}</td>
                    <td>{t('7 segundos')}</td>
                  </tr>
                </tbody>
              </Table>
              <p>
                {t(
                  'Para chegarmos nesse cálculo, levamos em consideração que um vídeo é formado por “takes”, que são pequenas filmagens mostrando diferentes ângulos de um ambiente.',
                )}
              </p>
              <p>
                {t(
                  'Para uma moradia, recomenda-se o uso de dois a três takes de 5 segundos para mostrar um ambiente. No total, esse vídeo teria cerca de 12 a 15 segundos.',
                )}
              </p>
              <p>
                {t(
                  'Em espaços superiores a 50 m2, é recomendado utilizar mais takes, para que seja possível visualizar completamente o ambiente.',
                )}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose}>{t('Fechar')}</Button>
            </Modal.Footer>
          </Modal>

          <Button type="submit">{t('Continuar')}</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Inner;
