import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  /* height: 100vh; */

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  place-content: center;

  width: 100%;

  .logo {
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: 30px;
  }

  form {
    margin: 80px 0;
    max-width: 700px;
    width: 100%;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }

    @media (max-width: 980px) {
      padding: 10px;
    }
  }

  > a {
    color: #f9bb00;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#f9bb00')};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

export const InputBox = styled.div`
  div {
    display: flex;
    align-items: center;
  }

  p {
    padding: 10px;
    text-align: left;
  }

  input[type='checkbox'] {
    transform: scale(2);
    margin-right: 10px;
  }

  & + div {
    margin-top: 8px;
  }
`;
