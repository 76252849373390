import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useTranslation } from 'react-i18next';
import modelingBlackImg from '../../assets/modeling_black.png';
import modelingGoldImg from '../../assets/modeling_gold.png';
import requestBlackImg from '../../assets/request_black.png';
import requestGoldImg from '../../assets/request_gold.png';
import targetBlackImg from '../../assets/target_black.png';
import targetGoldImg from '../../assets/target_gold.png';

import logoImg from '../../assets/logo.png';
import {
  Container,
  Content,
  Card,
  CardList,
  ButtonSubmit,
  CardBody,
  CardImage,
} from './styles';

const ChooseService: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [selectedType, setSelectedType] = useState('');
  const { t } = useTranslation();
  const subjectMarketing = t('Campanha%20Publicitária');
  const subjectOthers = t('Outros');
  const preferTalkWhatsapp = t('Prefiro falar pelo whatsapp');

  const handleSubmit = () => {
    switch (selectedType) {
      case 'modeling':
        history.push('/modeling');
        break;
      case 'marketing':
        window.open(
          `mailto:leonardo@gugale.com.br?subject=${subjectMarketing}`,
        );
        Swal.fire({
          icon: 'info',
          title: t('Envie um e-mail para gente.'),
          text: t(
            'Seu cliente de e-mail foi aberto para que você possa nos enviar uma mensagem!',
          ),
          footer: `<a href="https://api.whatsapp.com/send?l=pt&phone=5514997830667">${preferTalkWhatsapp}</a>`,
        });
        break;
      case 'others':
        window.open(`mailto:leonardo@gugale.com.br?subject=${subjectOthers}`);
        Swal.fire({
          icon: 'info',
          title: t('Envie um e-mail para gente.'),
          text: t(
            'Seu cliente de e-mail foi aberto para que você possa nos enviar uma mensagem!',
          ),
          footer: `<a href="https://api.whatsapp.com/send?l=pt&phone=5514997830667">${preferTalkWhatsapp}</a>`,
        });
        break;
      default:
        break;
    }
  };

  const handleSelectedType = useCallback(e => {
    setSelectedType(e.target.value);
  }, []);

  return (
    <Container>
      <Content>
        <img className="logo" src={logoImg} alt="Nominal 3D" width="180" />

        <h2>{t('O que você precisa?')}</h2>
        <p>{t('Selecione clicando em cima do ícone')}</p>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <CardList>
            <label htmlFor="modeling">
              <Card>
                <CardImage isSelected={selectedType === 'modeling'}>
                  <label htmlFor="modeling">
                    <img
                      height="90"
                      src={
                        selectedType === 'modeling'
                          ? modelingGoldImg
                          : modelingBlackImg
                      }
                      alt={t('Arquitetura')}
                    />
                  </label>
                  <input
                    type="checkbox"
                    id="modeling"
                    value="modeling"
                    onChange={handleSelectedType}
                  />
                </CardImage>
                <CardBody>
                  <p>{t('Arquitetura')}</p>
                </CardBody>
              </Card>
            </label>
            <label htmlFor="marketing">
              <Card>
                <CardImage isSelected={selectedType === 'marketing'}>
                  <label htmlFor="marketing">
                    <img
                      height="120"
                      src={
                        selectedType === 'marketing'
                          ? targetGoldImg
                          : targetBlackImg
                      }
                      alt={t('Campanhas Publicitárias')}
                    />
                  </label>
                  <input
                    type="checkbox"
                    id="marketing"
                    value="marketing"
                    onChange={handleSelectedType}
                  />
                </CardImage>
                <CardBody>
                  <p>{t('Campanhas Publicitárias')}</p>
                </CardBody>
              </Card>
            </label>
            <label htmlFor="others">
              <Card>
                <CardImage isSelected={selectedType === 'others'}>
                  <label htmlFor="others">
                    <img
                      height="120"
                      // width="162"
                      src={
                        selectedType === 'others'
                          ? requestGoldImg
                          : requestBlackImg
                      }
                      alt={t('Outros 3D')}
                    />
                  </label>
                  <input
                    type="checkbox"
                    id="others"
                    value="others"
                    onChange={handleSelectedType}
                  />
                </CardImage>
                <CardBody>
                  <p>{t('Outros 3D')}</p>
                </CardBody>
              </Card>
            </label>
          </CardList>

          {selectedType && (
            <ButtonSubmit type="submit">{t('Continuar')}</ButtonSubmit>
          )}
        </Form>
      </Content>
    </Container>
  );
};

export default ChooseService;
