import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Routes from './routes';
import GlobalStyle from './styles/global';
import en from './locales/en/translation.json';
import ptbr from './locales/ptbr/translation.json';

const resources = {
  en: {
    translation: en,
  },
  ptbr: {
    translation: ptbr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'ptbr',

  interpolation: {
    escapeValue: false,
  },
});

const App: React.FC = () => (
  <BrowserRouter>
    <Routes />
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
