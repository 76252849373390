import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';
import Modeling from '../pages/Modeling';
import ServiceType from '../pages/ServiceType';
import Outer from '../pages/Outer';
import Inner from '../pages/Inner';
import Budget from '../pages/Budget';
import ChooseService from '../pages/ChooseService';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" component={Login} exact />
    <Route path="/modeling" component={Modeling} />
    <Route path="/service-type" component={ServiceType} />
    <Route path="/outer" component={Outer} />
    <Route path="/inner" component={Inner} />
    <Route path="/budget" component={Budget} />
    <Route path="/choose-service" component={ChooseService} />
  </Switch>
);

export default Routes;
