import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useTranslation } from 'react-i18next';
import interiorBlackImg from '../../assets/interior_black.png';
import interiorGoldImg from '../../assets/interior_gold.png';
import exteriorBlackImg from '../../assets/building_black.png';
import exteriorGoldImg from '../../assets/building_gold.png';
import logoImg from '../../assets/logo.png';
import {
  Container,
  Content,
  Card,
  CardImage,
  CardBody,
  CardList,
  ButtonSubmit,
} from './styles';

interface Request {
  modelingFootage: number;
}

const ServiceType: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedOuter, setSelectedOuter] = useState(false);
  const [selectedInner, setSelectedInner] = useState(false);
  const [selectedType] = useState(
    localStorage.getItem('@Nominal:constructionType'),
  );

  const handleSelectedOuter = useCallback(() => {
    setSelectedOuter(!selectedOuter);
  }, [selectedOuter]);

  const handleSelectedInner = useCallback(() => {
    setSelectedInner(!selectedInner);
  }, [selectedInner]);

  const handleSubmit = useCallback(
    async (data: Request) => {
      try {
        formRef.current?.setErrors({});

        if (selectedOuter && selectedInner) {
          history.push({
            pathname: '/outer',
            state: { hasNextInnerPage: true },
          });
        } else if (selectedOuter) {
          history.push({
            pathname: '/outer',
            state: { hasNextInnerPage: false },
          });
        } else if (selectedInner) {
          history.push('inner');
        } else if (data.modelingFootage > 0) {
          history.push('budget');
        } else {
          throw Error(
            t('Você precisa selecionar pelo meno um tipo de serviço'),
          );
        }
      } catch (err) {
        Swal.fire({
          title: 'Error!',
          text: t('Por favor, selecione um dos tipos de serviço.'),
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      }
    },
    [history, selectedOuter, selectedInner, t],
  );

  return (
    <Container>
      <Content>
        <img className="logo" src={logoImg} alt="Nominal 3D" width="180" />

        <h2>{t('Escolha o serviço de renderização')}</h2>
        <p>{t('Selecione clicando em cima uma ou mais imagens')}</p>

        <Form ref={formRef} onSubmit={handleSubmit}>
          {selectedType && (
            <CardList>
              <label htmlFor="outerType">
                <Card>
                  <CardImage isSelected={selectedOuter}>
                    <label htmlFor="outerType">
                      <img
                        height="120"
                        src={selectedOuter ? exteriorGoldImg : exteriorBlackImg}
                        alt={t('Arquitetura Externa')}
                      />
                    </label>
                    <input
                      type="checkbox"
                      id="outerType"
                      onChange={handleSelectedOuter}
                    />
                  </CardImage>
                  <CardBody>
                    <p>{t('Arquitetura Externa')}</p>
                  </CardBody>
                </Card>
              </label>
              <label htmlFor="innerType">
                <Card>
                  <CardImage isSelected={selectedInner}>
                    <label htmlFor="innerType">
                      <img
                        height="120"
                        src={selectedInner ? interiorGoldImg : interiorBlackImg}
                        alt={t('Arquitetura Interiores')}
                      />
                    </label>
                    <input
                      type="checkbox"
                      id="innerType"
                      onChange={handleSelectedInner}
                    />
                  </CardImage>
                  <CardBody>
                    <p>{t('Arquitetura Interiores')}</p>
                  </CardBody>
                </Card>
              </label>
            </CardList>
          )}

          <ButtonSubmit type="submit">{t('Continuar')}</ButtonSubmit>
        </Form>
      </Content>
    </Container>
  );
};

export default ServiceType;
