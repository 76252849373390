/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Button from '../../components/Button';

interface ContentProps {
  outer: boolean;
  inner: boolean;
}

export const MaxWidth = styled.div<ContentProps>`
margin-top: 20px;
display: flex;
justify-content: center;
width: 100%;
max-width: 900px;

${props =>
    props.outer &&
    props.inner &&
    css`
    justify-content: space-between;
  `}
`;

export const Container = styled.div`
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  place-content: center;

  width: 100%;
  /* max-width: 900px; */

  @media (max-width: 980px) {
    padding: 10px;
  }

  .logo {
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: 30px;
  }

  form {
    width: 700px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: #f9bb00;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#f9bb00')};
    }

    svg {
      margin-right: 16px;
    }
  }

  > div {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;

    ${props =>
    props.outer &&
    props.inner &&
    css`
        justify-content: space-between;
      `}
  }

  small {
    color: #f9bb00;
  }
`;
export const Subtotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 32px;
    background: #fff;
    color: #2f2f2f;
    padding: 15px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  p,
  h3 {
    font-size: 18px;
  }

  h3 {
    font-size: 22px;
  }
`;

export const Total = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 60px;
  margin-bottom: 30px;

  h1 {
    font-size: 48px;

    svg {
      font-size: 20px;
    }
  }

  h4 {
    & + h4 {
      margin-top: 15px;
    }

    a {
      color: #f9bb00;
      text-decoration: none;
    }
  }
`;

export const ButtonWithMarginRight = styled(Button)`
  margin-right: 30px;

  border: 1px solid #f9bb00;
  background: transparent;
  color: #f9bb00;

  &:hover {
    background: #f9bb00;
    color: #312e38;
  }
`;

export const ButtonOutline = styled(Button)`
  border: 1px solid #f9bb00;
  background: transparent;
  color: #f9bb00;
  max-width: 700px;

  &:hover {
    background: #f9bb00;
    color: #312e38;
  }

  ${props =>
    props.disabled &&
    css`
      border: 1px solid #5a5a5a;
      color: #5a5a5a;

      &:hover {
        cursor: not-allowed;
        background: transparent;
        color: #5a5a5a;
      }
    `}
`;

export const ButtonSuccess = styled(Button)`
  background: #16a085;
  color: #fff;
  max-width: 700px;

  &:hover {
    background: ${shade(0.2, '#16a085')};
  }
`;
