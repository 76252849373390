import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, InputBox } from './styles';

interface Request {
  modelingFootage: number | null;
  photosQuantity: number | null;
  videoSeconds: number | null;
}

interface Price {
  photos: number;
  video: number;
  total: number;
  days: number;
}

interface HistoryProps extends History {
  hasNextInnerPage?: boolean;
}

const Outer: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory<HistoryProps>();
  const { t } = useTranslation();
  const [constructionType] = useState(
    localStorage.getItem('@Nominal:constructionType') || '',
  );

  const handleSubmit = useCallback(
    async (data: Request) => {
      try {
        formRef.current?.setErrors({});

        const priceOuter: Price = {
          photos: 0,
          video: 0,
          total: 0,
          days: 0,
        };

        if (data.photosQuantity) {
          if (constructionType === 'edificio') {
            priceOuter.photos = data.photosQuantity * 500 + 500;
            priceOuter.total += priceOuter.photos;

            priceOuter.days += data.photosQuantity * 2 + 2;
          } else {
            priceOuter.photos = data.photosQuantity * 300 + 300;
            priceOuter.total += priceOuter.photos;

            priceOuter.days += data.photosQuantity * 1.5;
          }
        }

        if (data.videoSeconds) {
          if (constructionType === 'edificio') {
            priceOuter.video = data.videoSeconds * 35;
            priceOuter.total += priceOuter.video;

            priceOuter.days += data.videoSeconds * 0.25;
          } else {
            priceOuter.video = data.videoSeconds * 25;
            priceOuter.total += priceOuter.video;

            priceOuter.days += data.videoSeconds * 0.168;
          }
        }

        localStorage.setItem('@Nominal:priceOuter', JSON.stringify(priceOuter));

        if (history.location.state.hasNextInnerPage) {
          history.push('/inner');
        } else {
          history.push('/budget');
        }
      } catch (err) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    },
    [history, constructionType],
  );

  return (
    <Container>
      <Content>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
          integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
          crossOrigin="anonymous"
        />
        <img className="logo" src={logoImg} alt="Nominal 3D" width="180" />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>{t('Arquitetura Externa')}</h1>
          <InputBox>
            <p>{t('Deseja fotos?')}</p>
            <div>
              <Input
                type="number"
                name="photosQuantity"
                placeholder={t('Quantidade de fotos')}
              />
            </div>
          </InputBox>
          <InputBox>
            <p>{t('Deseja ter o vídeo?')}</p>
            <div>
              <Input
                type="number"
                name="videoSeconds"
                placeholder={t('Tamanho do vídeo em segundos')}
              />
            </div>
          </InputBox>

          <Button type="submit">{t('Continuar')}</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Outer;
