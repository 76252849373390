import styled from 'styled-components';
import { shade } from 'polished';

import loginBackgroundImg from '../../assets/login-background.jpg';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  place-content: center;

  width: 100%;
  max-width: 700px;

  .logo {
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: 30px;
  }

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }

    label {
      & + label {
        margin-left: 10px;
      }

      input {
        margin-right: 8px;
      }
    }

    button {
      margin-top: 30px;
    }
  }

  > a {
    color: #f9bb00;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#f9bb00')};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${loginBackgroundImg}) no-repeat center;
  background-size: cover;
`;

export const Select = styled.select`
  background: #232129;
  color: #f4ede8;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  border: 0;
  margin-top: 8px;
`;

export const LanguageBox = styled.div`
  margin-bottom: 15px;
`;
