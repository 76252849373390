import styled, { css } from 'styled-components';
import { shade } from 'polished';

import Button from '../../components/Button';

import loginBackgroundImg from '../../assets/login-background.jpg';

interface CardImageProps {
  isSelected: boolean;
}

export const Container = styled.div`
  /* height: 100vh; */ /* Se ativa essa propriedade, ele joga a logo p cima */

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  place-content: center;

  width: 100%;
  /* max-width: 700px; */

  .logo {
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: 30px;
  }

  form {
    margin: 40px 0;
    width: 980px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: #f9bb00;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#f9bb00')};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${loginBackgroundImg}) no-repeat center;
  background-size: cover;
`;

export const Card = styled.div`
  margin: 30px auto;
  width: 250px;
  height: 250px;
  border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: 0.4s;

  & + div {
    margin-left: 15px;
  }

  &:hover {
    transform: scale(0.9, 0.9);
    box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
      -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
  }
`;

export const CardImage = styled.div<CardImageProps>`
  width: inherit;
  height: inherit;
  border-radius: 40px;
  border: 10px solid transparent;

  label {
    width: inherit;
    height: inherit;
    border-radius: 40px;
    cursor: pointer;
  }

  img {
    margin-top: 35px;
    /* width: 128px; */
  }

  input {
    display: none;
  }

  ${props =>
    props.isSelected &&
    css`
      border: 10px solid #f5cb2d;
    `}
`;

export const CardBody = styled.div`
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  font-size: 22px;
  margin-top: -70px;

  p {
    text-shadow: 2px 2px #fafafa;
  }
`;

export const CardList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    margin: 30px auto;
  }

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

export const ButtonSubmit = styled(Button)`
  width: 360px;
  margin-top: 30px;
`;

export const InputBox = styled.div`
  div {
    display: flex;
    align-items: center;
  }

  p {
    padding: 10px;
    text-align: left;
  }

  input[type='checkbox'] {
    transform: scale(2);
    margin-right: 10px;
  }

  & + div {
    margin-top: 8px;
  }
`;

export const CardSmall = styled.div`
  margin: 30px auto;
  width: 200px;
  height: 200px;
  border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition: 0.4s;

  & + div {
    margin-left: 15px;
  }

  &:hover {
    transform: scale(0.9, 0.9);
    box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
      -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
  }
`;

export const CardBodySmall = styled.div`
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 15px;
  margin-top: -80px;
  height: 40px;

  p {
    text-shadow: 2px 2px #333;
  }
`;
