import styled from 'styled-components';
import { shade } from 'polished';
import { Modal } from 'react-bootstrap';
import Button from '../../components/Button';

export const Container = styled.div`
  /* height: 100vh; */

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  place-content: center;

  width: 100%;
  /* max-width: 980px; */

  .logo {
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: 30px;
  }

  form {
    margin: 80px 0;
    max-width: 700px;
    width: 100%;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    > a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }

    @media (max-width: 980px) {
      padding: 10px;
    }
  }

  > a {
    color: #f9bb00;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#f9bb00')};
    }

    svg {
      margin-right: 16px;
    }
  }
`;

export const InputBox = styled.div`
  & + div {
    margin-top: 20px;
  }

  section {
    display: flex;
    align-items: center;

    div + div,
    button {
      margin-left: 5px;
      margin-top: 0;
    }

    button {
      border: 1px solid #f44336;
      background: transparent;
      color: #f44336;
      width: 50px;

      &:hover {
        background: ${shade(0.2, '#f44336')};
        color: #fff;
      }
    }

    @media (max-width: 980px) {
      flex-direction: column;

      div + div,
      button {
        margin-left: 0;
        margin-top: 5px;
      }

      button {
        width: 100%;
      }
    }
  }

  p {
    padding: 10px;
    text-align: left;
  }

  input[type='checkbox'],
  input[type='radio'] {
    transform: scale(1.5);
    margin-right: 10px;
  }

  section {
    margin-top: 10px;
  }

  @media (min-width: 980px) {
    label + label {
      margin-left: 15px;
    }
  }
`;

export const ButtonOutline = styled(Button)`
  border: 1px solid #f9bb00;
  background: transparent;
  color: #f9bb00;

  &:hover {
    background: #f9bb00;
    color: #312e38;
  }
`;

export const ButtonLink = styled.a`
  background: transparent;
  border: 0;
  color: #f9bb00;
  margin-left: 20px;
  margin-top: 0;
  cursor: pointer;

  &:hover {
    color: #f9bb00;
    text-decoration: none;
  }
`;

export const ModalCustom = styled(Modal)`
  .modal-content {
    background: #fafafa;
  }

  .close {
    color: #fff !important;
  }

  table {
    margin-top: 5px;
    background-color: #fafafa;

    tr {
      border: 0;
    }
  }
`;
