import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Input';

import buildingBlackImg from '../../assets/building_black.png';
import buildingGoldImg from '../../assets/building_gold.png';
import houseBlackImg from '../../assets/house_black.png';
import houseGoldImg from '../../assets/house_gold.png';

import logoImg from '../../assets/logo.png';
import {
  Container,
  Content,
  Card,
  CardList,
  ButtonSubmit,
  InputBox,
  CardBody,
  CardImage,
  CardButton,
} from './styles';

interface Request {
  modelingFootage: number;
}

const Modeling: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [needModeling, setNeedModeling] = useState(true);
  const [selectedType, setSelectedType] = useState('');
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data: Request) => {
      try {
        formRef.current?.setErrors({});

        if (selectedType) {
          localStorage.setItem('@Nominal:constructionType', selectedType);
        } else {
          Swal.fire({
            title: 'Error!',
            text: t('Por favor, selecione um tipo de construção.'),
            icon: 'error',
            confirmButtonText: 'Ok',
          });
          return;
        }

        if (needModeling) {
          if (data.modelingFootage > 0) {
            if (selectedType === 'edificio') {
              localStorage.setItem(
                '@Nominal:modelingFootage',
                JSON.stringify(data.modelingFootage * 3.3),
              );
              const days = Math.round(data.modelingFootage / 200);
              localStorage.setItem(
                '@Nominal:modelingDays',
                JSON.stringify(days),
              );
            } else {
              localStorage.setItem(
                '@Nominal:modelingFootage',
                JSON.stringify(data.modelingFootage * 6.67),
              );
              const days = Math.round(data.modelingFootage / 100);
              localStorage.setItem(
                '@Nominal:modelingDays',
                JSON.stringify(days),
              );
            }

            history.push('budget');
          } else {
            Swal.fire({
              title: 'Error!',
              text: t('Por favor, digite o valor de m² da modelagem.'),
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          }
        } else {
          history.push('service-type');
        }
      } catch (err) {
        Swal.fire({
          title: 'Error!',
          text: t(
            'Por favor, ocorreu algum erro, tente novamente ou contate o suporte.',
          ),
          icon: 'error',
          confirmButtonText: 'Ok',
        });
      }
    },
    [history, needModeling, selectedType, t],
  );

  const handleSelectedType = useCallback(e => {
    setSelectedType(e.target.value);
  }, []);

  return (
    <Container>
      <Content>
        <img className="logo" src={logoImg} alt="Nominal 3D" width="180" />

        <h2>{t('Qual é o seu projeto?')}</h2>
        <p>{t('Selecione clicando em cima da imagem')}</p>

        <Form ref={formRef} onSubmit={handleSubmit}>
          <CardList>
            <label htmlFor="edificioType">
              <Card>
                <CardImage isSelected={selectedType === 'edificio'}>
                  <label htmlFor="edificioType">
                    <img
                      height="120"
                      src={
                        selectedType === 'edificio'
                          ? buildingGoldImg
                          : buildingBlackImg
                      }
                      alt={t('Edifício')}
                    />
                  </label>
                  <input
                    type="checkbox"
                    id="edificioType"
                    value="edificio"
                    onChange={handleSelectedType}
                  />
                </CardImage>
                <CardBody>
                  <p>{t('Edifício')}</p>
                </CardBody>
              </Card>
            </label>
            <label htmlFor="moradiaType">
              <Card>
                <CardImage isSelected={selectedType === 'moradia'}>
                  <label htmlFor="moradiaType">
                    <img
                      height="110"
                      src={
                        selectedType === 'moradia'
                          ? houseGoldImg
                          : houseBlackImg
                      }
                      alt={t('Casa')}
                    />
                  </label>
                  <input
                    type="checkbox"
                    id="moradiaType"
                    value="moradia"
                    onChange={handleSelectedType}
                  />
                </CardImage>
                <CardBody>
                  <p>{t('Casa')}</p>
                </CardBody>
              </Card>
            </label>
          </CardList>

          {selectedType && (
            <>
              <h2>{t('Precisa de serviço de modelagem?')}</h2>
              <CardList>
                <CardButton isSelected={needModeling}>
                  <label htmlFor="needModeling">
                    <p>{t('Sim, preciso de modelagem')}</p>
                  </label>
                  <input
                    type="checkbox"
                    id="needModeling"
                    onChange={() => setNeedModeling(true)}
                  />
                </CardButton>
                <CardButton isSelected={!needModeling}>
                  <label htmlFor="dontNeedModeling">
                    <p>{t('Não, já possuo modelagem')}</p>
                  </label>
                  <input
                    type="checkbox"
                    id="dontNeedModeling"
                    onChange={() => setNeedModeling(false)}
                  />
                </CardButton>
              </CardList>

              {needModeling && (
                <CardList>
                  <InputBox>
                    <div>
                      <Input
                        type="number"
                        name="modelingFootage"
                        placeholder={t('m² da modelagem')}
                      />
                    </div>
                  </InputBox>
                </CardList>
              )}
              <ButtonSubmit type="submit">{t('Continuar')}</ButtonSubmit>
            </>
          )}
        </Form>
      </Content>
    </Container>
  );
};

export default Modeling;
